/** @format */

import React, { useContext, useState } from "react";
import { ImGithub, ImLink } from "react-icons/im";
import { BiLogoPlayStore } from "react-icons/bi";
import { ReactComponent as FtLogoDark } from "./../assets/icons/42_Logo_dark.svg";
import { ReactComponent as FtLogoLight } from "./../assets/icons/42_Logo_light.svg";
import { ThemeContext } from "../contexts/themeContext";

function Project({ project }) {
	const { theme } = useContext(ThemeContext);
	const skill_size = project.skills.length;
	const [isHovered, setIsHovered] = useState(false);

	const handleMouseEnter = () => {
		setIsHovered(true);
	};

	const handleMouseLeave = () => {
		setIsHovered(false);
	};

	return (
		<>
			<div className="flex flex-col gap-8">
				<div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
					{isHovered ? (
						<video
							autoPlay={true}
							loop={true}
							src={project?.preview}
							alt={project.title}
							className="w-full aspect-[14/9] object-cover"
						/>
					) : (
						<img
							src={project.imageLink}
							alt={project.title}
							className="w-full aspect-[14/9] object-cover"
						/>
					)}
				</div>

				<div className="flex flex-col gap-3">
					<div className="flex flex-row justify-between items-center">
						<h3 className="text-2xl">{project.title}</h3>
						<span className="flex flex-row gap-6">
							{project.ft_link && (
								<a href={project.ft_link}>
									{theme === "dark" ? (
										<FtLogoDark className="h-6 w-6" />
									) : (
										<FtLogoLight className="h-6 w-6" />
									)}
								</a>
							)}
							{project.githubLink && (
								<a href={project.githubLink}>
									<ImGithub size={24} />
								</a>
							)}
							{project.storeLink && (
								<a href={project.storeLink}>
									<BiLogoPlayStore size={24} />
								</a>
							)}
							{project.link && (
								<a href={project.link}>
									<ImLink size={22} />
								</a>
							)}
						</span>
					</div>
					<p className="text-base leading-6">{project.description}</p>
					<span className="text-base leading-6 flex flex-row flex-wrap text-[#6CACE4]">
						{project.skills.map((skill, index) => (
							<p className="whitespace-nowrap">
								{skill}
								{index < skill_size - 1 ? " -\u00A0" : ""}
							</p>
						))}
					</span>
				</div>
			</div>
		</>
	);
}

export default Project;
